<template>
    <div class="addclass">
        <div class="content">
            <div class="cont-mid">
                <el-form ref="form" :rules="rules" :model="form" label-width="200px">
                    <el-form-item label="课节名称：" prop="name">
                        <el-input v-model="form.name" maxlength="100" show-word-limit style="width:555px"></el-input>
                    </el-form-item>

                    <el-form-item label="开放时间：" required>
                        <dateTimePikcer @dateTimePicker="handleTimeChange" :pickerType="'point'"
                            :beginTime="form.lessonsBeginTime">
                        </dateTimePikcer>
                    </el-form-item>

                    <el-form-item label="主讲教师：" :required="true">
                        <el-input v-model="form.lecturerInfo.name" style="width:300px"></el-input>
                    </el-form-item>

                    <el-form-item label="教师简介：">
                        <el-input type="textarea" class="textarea" :rows="6" maxlength="2500" show-word-limit
                            v-model="form.lecturerInfo.introduce" resize="none"></el-input>
                    </el-form-item>

                    <el-form-item label="头像：">
                        <UploadCover @uploadCover="handleAvatarChange" :xcover="form.lecturerInfo.cover"></UploadCover>
                    </el-form-item>

                    <el-form-item label="视频：" :required="true">
                        <uploadFile @uploadFile="handleFileChange" :name="'videoIdentifiers'" :list="form.videoNames"
                            :tips="{ image: false, url: true }" :sourceType=1 />
                    </el-form-item>

                    <el-form-item class="el-form-item-btns">
                        <el-button @click="$router.push(`/lesson/${form.courseProjectId}?breadNumber=4`)">取消
                        </el-button>
                        <el-button type="primary" @click="onSubmit">确定</el-button>
                    </el-form-item>
                    <br />
                </el-form>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "newcourse",
    data() {
        return {
            isDisable: false,
            form: {
                lecturerInfo: {
                    name: '',
                    introduce: ''
                },
                teacher: {
                    id: 0
                },
                assistant: {
                    id: 0
                }
            },
            rules: {
                name: [{
                    required: true,
                    message: "请输入课节名称",
                    trigger: "blur"
                }],
                video: [{
                    required: true
                }],
                teacherName: [{
                    required: true,
                    message: "请输入教师名称",
                    trigger: "blur"
                }],
            },
        };
    },
    computed: {},
    components: {},
    methods: {
        handleTimeChange(data) {
            // console.log(data);
            this.form.lessonsBeginDate = data.bDate;
            this.form.lessonsBeginTime = data.bTime;
        },
        handleFileChange(data) {
            // console.log(data);
            this.form[data.name] = data.fileMD5List;
        },
        async onSubmit() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.commitForm();
                }
            })
        },
        async commitForm() {
            this.form.lessonsName = this.form.name
            this.form.lessonsPattern = this.$route.query.lessonsPattern
            this.form.teacherId = this.form.teacherId || this.form.teacher.id || ''
            this.form.assistantId = this.form.assistantId || this.form.assistant.id || ''
            // console.log(this.form);
            this.form.lecturerInfo = JSON.stringify(this.form.lecturerInfo);
            let resData = await this.$Api.Course.editLesson(this.form);
            if (resData.data.code === 200) {
                this.$notify({
                    title: "成功",
                    message: "编辑成功",
                    type: "success"
                });
                setTimeout(() => {
                    this.$router.push(`/lesson/${this.form.courseProjectId}?breadNumber=4`);
                }, 500);
            } else {
                this.$notify.error({
                    title: "错误",
                    message: resData.msg
                });
            }
        },
        // 获取课节信息
        async getLessonInfo() {
            let id = this.$route.params.id;
            let resData = await this.$Api.Course.getLesson(id);
            console.log('课节信息:', resData)
            if (resData) {
                resData.data.lecturerInfo = JSON.parse(resData.data.lecturerInfo);
                this.form = resData.data
                if (!this.form.assistant) this.form.assistant = {
                    id: 0
                }
                if (!this.form.teacher) this.form.teacher = {
                    id: 0
                }
                if (!resData.data.lecturerInfo) {
                    console.log('1111')
                    this.form.lecturerInfo = {
                        name: '',
                        introduce: ''
                    }
                }
                // console.log(this.form);
            }
        },
        // 处理头像
        handleAvatarChange(data) {
            console.log('头像:', data)
            this.form.lecturerInfo.cover = data.md5
        },
    },
    // mounted() {
    //     this.getLessonInfo();
    // },
    activated() {
        this.getLessonInfo();
    },
    deactivated() {
        this.form = {
            teacher: {
                id: 0
            },
            assistant: {
                id: 0
            },
            lecturerInfo: {
                name: '',
                introduce: ''
            },
        };
        this.$refs["form"].resetFields();
    },
    created() { }
};
</script>

<style lang="less">
.lesson_edit .el-form-item-btns {
    margin-top: 60px;
}
</style>